import React from 'react';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';
import ContentModule from 'sensortower-components/src/base-components/ContentModule';
import { SubnavigationItem } from '../NavigationItemLink/NavigationItemLink';
import HamburgerMenu from '../HamburgerMenu';
import { LinkProps } from '../Link';
import { sidekick } from '../../utils/sidekick';
import type { NavigationItemProps } from '../NavigationItem';

export interface CollectionNavigationBarProps {
  items: Array<SubnavigationItem | LinkProps>;
  sidekickLookup: any;
}

const setNavSubGroup = (navItem: NavigationItemProps) => {
  if (!navItem.subNavigation) {
    return;
  }
  if (!navItem.variant) {
    navItem.variant = 'subgroup';
    navItem.subNavigation.forEach((subNav) => setNavSubGroup(subNav));
  }
};

export const CollectionNavigationBar = ({ items, sidekickLookup }: CollectionNavigationBarProps) => {
  items?.forEach((item) => {
    const navItem = item;

    if (navItem && 'subNavigation' in navItem && navItem.subNavigation) {
      navItem.subNavigation.forEach((subNavItem) => {
        setNavSubGroup(subNavItem);
      });
    }
  });

  return (
    <ErrorBoundary>
      <Root {...sidekick(sidekickLookup)} data-testid="CollectionNavigationBar">
        <Grid container spacing={0.5} sx={{ alignItems: 'center' }}>
          {items?.map((item) => (
            <Grid item key={item.id} sx={{ height: '100%' }} data-testid="CollectionNavigationBar-navigationItem">
              <ContentModule {...(item as any)} />
            </Grid>
          ))}
        </Grid>

        <Menu>
          <HamburgerMenu items={items} />
        </Menu>
      </Root>
    </ErrorBoundary>
  );
};

const Root = styled(Box, {
  name: 'NavigationBar',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => ({
    height: '100%',
    ...(styles.root as any),
  }),
})<{ variant?: string }>(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: '100%',
    '& > .MuiGrid-root': {
      justifyContent: 'flex-end',
    },
  },
  [theme.breakpoints.down('md')]: {
    '&.MuiBox-root': {
      zIndex: '1 !important',
      opacity: '1 !important',
      top: '24px !important',
      right: '32px !important',
      width: '32px !important',
      marginLeft: 'auto !important',
      position: 'absolute',
      left: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundColor: 'transparent',
      transform: 'none !important',
      transition: 'opacity 200ms linear !important',
    },
  },
  [theme.breakpoints.down('lg')]: {
    '& .MuiGrid-root': {
      display: 'none',
    },
  },

  /** This is the login button */
  '& .MuiButton-contained': {
    minWidth: 90,
    height: 'auto',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(0.25),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRadius: 56 / 2, // Height is unknown, so it's a bit hacky
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    fontWeight: 600,
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));

const Menu = styled(Box, {
  name: 'NavigationBar',
  slot: 'Menu',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => ({
    height: '100%',
    ...(styles.root as any),
  }),
})<{ variant?: string }>(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

export default CollectionNavigationBar;
